import Vue from "vue";

// local
// export const domain = "http://127.0.0.1:8000/";
// export const url_path = "http://127.0.0.1:8000/storage/";

// demo
export const domain = "https://api-logistic-demo.ksta.co/";
export const url_path = "https://api-logistic-demo.ksta.co/storage/";
export const domain_admin = "https://admin-logistic-demo.ksta.co/";

// Dev
// export const domain = "https://tnl-api.ksta.co/";
// export const url_path = "https://tnl-api.ksta.co/storage/";
// export const domain_admin = "https://tnl-admin.ksta.co/";

// server
// export const domain = "https://tnlogistic.com:3000/";
// export const url_path = "https://tnlogistic.com:3000/storage/";
// export const domain_admin = "https://tnlogistic.com:8443/";

// prod
// export const domain = "http://13.250.131.64/";
// export const domain = "https://tnlogistic.com:3000/";
// export const domain_admin = "https://tnlogistic.com:8443/";

export const eventBus = new Vue();
