<template>
  <div>
    <v-card tile mb-4>
      <v-layout pl-6 py-1 class="justify-center align-center">
        <v-flex shrink mr-2>
          <img width="60px" src="../../assets/images/logo.png" alt="" />
        </v-flex>
        <v-flex>
          <span class="mr-3 font-weight-bold">TN</span>
          <span>DEMO LOGISTICS</span></v-flex
        >
        <v-spacer></v-spacer>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuLists: [
        {
          title: "Profile",
          link: "profile",
          icon: "mdi-account-multiple-outline",
        },
        { title: "Logout", link: "logout", icon: "mdi-logout-variant" },
      ],
    };
  },
  methods: {
    async onHandleProfileMenu(link) {
      switch (link) {
        case "profile":
          this.$router.push(link);
          break;
        case "logout":
          await this.$store.dispatch("auth/logout");
          window.location = "/";
          break;
        default:
          return true;
      }
    },
  },
};
</script>

<style></style>
