<template>
  <div class="mx-8 table-tender">
    <!-- Main Table -->
    <v-data-table
      :headers="headers"
      :items="items"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="tender_id"
      show-expand
      show-select
      class="elevation-1"
      v-model="valMain"
      hide-default-footer
    >
      <!-- <template v-slot:header.select="{ header }"> </template> -->
      <!-- Custom Main Table Item rows -->
      <template v-slot:item="{ item, expand, isExpanded, isSelected, select }">
        <tr :class="isExpanded && 'td-row-active'">
          <td colspan="3" class="text-start border-right">
            <v-layout shrink>
              <v-flex>
                <v-icon v-if="!isExpanded" @click="expand(!isExpanded)">
                  mdi-chevron-down
                </v-icon>
                <v-icon v-else @click="expand(!isExpanded)">
                  mdi-chevron-up
                </v-icon>
              </v-flex>
              <v-flex class="ml-n10">
                <v-simple-checkbox
                  color="#338A68"
                  :value="isSelected"
                  @input="select($event)"
                ></v-simple-checkbox>
              </v-flex>
            </v-layout>
          </td>

          <td class="text-center border-right">
            <v-icon
              color="#338A68"
              :disabled="isAdd"
              @click="onHandleAddItem(item)"
            >
              mdi-plus-circle-outline
            </v-icon>
          </td>
          <td class="text-center">{{ item.no }}</td>
          <td class="text-left">{{ item.status ? item.status : "--" }}</td>
          <td class="text-left">
            {{ item.reason ? item.reason : "--" }}
          </td>
          <td class="text-left">
            {{
              item.order_detail &&
              item.order_detail.order &&
              item.order_detail.order.order_id
                ? item.order_detail.order.order_id
                : "--"
            }}
          </td>
          <td class="text-left">
            {{ item.carrier_name ? item.carrier_name : "--" }}
          </td>
          <td class="text-left">
            {{
              item.order_detail &&
              item.order_detail.order &&
              item.order_detail.order.customer_name
                ? item.order_detail.order.customer_name
                : "--"
            }}
          </td>
          <td class="text-left">
            {{
              item.order_detail &&
              item.order_detail.order &&
              item.order_detail.order.inv_po
                ? item.order_detail.order.inv_po
                : "--"
            }}
          </td>
          <td class="text-left">
            {{
              item.order_detail &&
              item.order_detail.order &&
              item.order_detail.order.booking_no
                ? item.order_detail.order.booking_no
                : "--"
            }}
          </td>
          <td class="text-left">
            {{
              item.order_detail && item.order_detail.cy_place_name
                ? item.order_detail.cy_place_name
                : "--"
            }}
          </td>
          <td class="text-left">
            <span
              v-if="
                item.order_detail &&
                  item.order_detail.order &&
                  item.order_detail.order.cy_date
              "
            >
              {{ item.order_detail.order.cy_date | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>--</span>
          </td>
          <td class="text-left">
            <span
              v-if="
                item.order_detail &&
                  item.order_detail.order &&
                  item.order_detail.order.release_date
              "
            >
              {{ item.order_detail.order.release_date | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>--</span>
          </td>
          <td class="text-left">
            <span
              v-if="item.order_detail && item.order_detail.unload_load_date1"
            >
              {{ item.order_detail.unload_load_date1 | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>--</span>
          </td>

          <td class="text-center">
            {{ item.model_name ? item.model_name : "--" }}
          </td>
          <td class="text-left">
            {{
              item.order_detail && item.order_detail.wh_factory_1_name
                ? item.order_detail.wh_factory_1_name
                : "--"
            }}
          </td>
          <td class="text-left">
            <span
              v-if="item.order_detail && item.order_detail.unload_load_date2"
            >
              {{ item.order_detail.unload_load_date2 | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>--</span>
          </td>
          <td class="text-left">
            {{
              item.order_detail && item.order_detail.wh_factory_2_name
                ? item.order_detail.wh_factory_2_name
                : "--"
            }}
          </td>
          <td
            class="text-left"
            :style="
              checkQty(item) == item.qty
                ? { color: '#338A68' }
                : { color: '#EB5757' }
            "
          >
            {{ item.qty ? item.qty : "--" }}/{{ checkQty(item) }}
          </td>
          <td class="text-center">
            {{
              item.order_detail && item.order_detail.container_size_name
                ? item.order_detail.container_size_name
                : "--"
            }}
          </td>
          <td class="text-center">
            {{
              item.order_detail && item.order_detail.container_isotype_name
                ? item.order_detail.container_isotype_name
                : "--"
            }}
          </td>
          <td>
            {{
              item.order_detail && item.order_detail.return_place_name
                ? item.order_detail.return_place_name
                : "--"
            }}
          </td>
          <td class="text-center">
            <span
              v-if="
                item.order_detail &&
                  item.order_detail.order &&
                  item.order_detail.order.closing_date
              "
            >
              {{ item.order_detail.order.closing_date | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>--</span>
          </td>
          <td class="text-center">
            {{
              item.order_detail &&
              item.order_detail.order &&
              item.order_detail.order.closing_time
                ? subStringTime(item.order_detail.order.closing_time)
                : "--"
            }}
          </td>
          <td class="text-center">
            <span
              v-if="
                item.order_detail &&
                  item.order_detail.order &&
                  item.order_detail.order.frd
              "
            >
              {{ item.order_detail.order.frd | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>--</span>
          </td>
          <td class="text-left">
            {{
              item.order_detail &&
              item.order_detail.order &&
              item.order_detail.order.shipping_agent_name
                ? item.order_detail.order.shipping_agent_name
                : "--"
            }}
          </td>
          <td class="text-left">
            {{
              item.order_detail &&
              item.order_detail.order &&
              item.order_detail.order.shipping_name
                ? item.order_detail.order.shipping_name
                : "--"
            }}
          </td>
          <td class="text-center">
            <span
              v-if="
                item.order_detail &&
                  item.order_detail.order &&
                  item.order_detail.order.updated_at
              "
            >
              {{ item.order_detail.order.updated_at | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>--</span>
          </td>
          <td class="text-center">
            {{
              item.order_detail &&
              item.order_detail.order &&
              item.order_detail.order.user_name
                ? item.order_detail.order.user_name
                : "--"
            }}
          </td>
        </tr>
      </template>

      <!-- Expanable Table in Main Table -->
      <template v-slot:expanded-item="{ headers, item, index }">
        <td :colspan="headers.length">
          <!-- Child Table -->
          <v-data-table
            :headers="headersChild"
            :items="item.accept_tender"
            item-key="accept_tender_id"
            show-select
            v-model="valSub"
            class="elevation-1 table-child"
            hide-default-footer
          >
            <!-- Custom Child Table Item rows -->
            <template v-slot:item="{ item, index, isSelected, select }">
              <!-- form add -->
              <tr
                v-if="
                  isAdd && indexChild === index && item.tender_id === indexMain
                "
              >
                <td>
                  <v-simple-checkbox
                    color="#338A68"
                    :value="isSelected"
                    @input="select($event)"
                  ></v-simple-checkbox>
                </td>
                <td class="text-center">
                  <v-layout>
                    <v-flex>
                      <v-btn
                        v-if="isEdit"
                        fab
                        depressed
                        small
                        text
                        @click="onHandleCancel(item, index)"
                      >
                        <v-icon mx-2 color="red">
                          mdi-close-circle-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        fab
                        depressed
                        small
                        text
                        @click="onHandleDeleteChild(item, index)"
                      >
                        <v-icon mx-2 color="red">
                          mdi-close-circle-outline
                        </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        fab
                        depressed
                        small
                        text
                        @click="onHandleSaveChild(item, 'save')"
                      >
                        <v-icon color="#338A68" class="mx-2">
                          mdi-content-save-outline
                        </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </td>
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center">
                  {{ item.cost_created_at ? item.cost_created_at : "--" }}
                </td>
                <td class="text-center">
                  {{ item.unload_load_date1 ? item.unload_load_date1 : "--" }}
                </td>
                <td class="text-center">
                  {{ item.wh_factory_1_name ? item.wh_factory_1_name : "--" }}
                </td>
                <td class="text-center">
                  <TextInput
                    height="30px"
                    inputRule="number"
                    hideDetails
                    :model="item.qty"
                    rulse="fieldRequire"
                    @update-data="item.qty = $event"
                  />
                </td>
                <td class="text-center">
                  <AutocompleteInput
                    height="30px"
                    required
                    borderColor="green"
                    hideDetail
                    :model="item.driver_id"
                    rulse="fieldRequire"
                    :items="driverListDefault"
                    itemText="name"
                    itemValue="driver_id"
                    @update-data="
                      updateDataChildSelect(item, $event, 'driverList')
                    "
                  />
                </td>
                <td class="text-center">
                  <AutocompleteInput
                    height="30px"
                    required
                    borderColor="green"
                    hideDetail
                    :model="item.truck_license_id"
                    rulse="fieldRequire"
                    :items="truckLicenseListDefault"
                    itemText="truck_plate_no"
                    itemValue="truck_plate_no"
                    @update-data="
                      updateDataChildSelect(item, $event, 'truckLicenseList')
                    "
                  />
                </td>
                <td class="text-center">
                  <AutocompleteInput
                    height="30px"
                    required
                    borderColor="green"
                    hideDetail
                    :model="item.trailer_license_id"
                    rulse="fieldRequire"
                    :items="trailerLicenseListDefault"
                    itemText="tail_plate_no"
                    itemValue="tail_plate_no"
                    @update-data="
                      updateDataChildSelect(item, $event, 'trailerLicenseList')
                    "
                  />
                </td>
                <td class="text-center">
                  {{ item.job_status_name }}
                </td>
                <td class="text-center">
                  <v-btn
                    dark
                    small
                    color="#338A68"
                    @click="onHandleSaveChild(item, 'saveNew')"
                  >
                    <span class="text-capitalize font-weight-black">
                      save&new
                    </span>
                  </v-btn>
                </td>
                <td></td>
              </tr>

              <!-- form show -->
              <tr v-else>
                <!-- <td style="width: 34px"></td> -->
                <td>
                  <v-simple-checkbox
                    color="#338A68"
                    :value="isSelected"
                    @input="select($event)"
                  ></v-simple-checkbox>
                </td>
                <!-- <td style="width: 130px"></td> -->

                <td class="text-center">
                  <v-layout>
                    <v-flex>
                      <v-btn
                        fab
                        depressed
                        small
                        text
                        :disabled="
                          item.job_status_name === 'Pending' ||
                            item.job_status_name === 'Complete' ||
                            item.job_status_name === 'Cancel'
                        "
                        @click="onHandleDeleteChild(item, index)"
                      >
                        <v-icon :disabled="isAdd" color="#000">
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        fab
                        depressed
                        small
                        text
                        :disabled="
                          item.job_status_name === 'Pending' ||
                            item.job_status_name === 'Complete' ||
                            item.job_status_name === 'Cancel'
                        "
                        @click="onHandleEditChild(item, index)"
                      >
                        <v-icon :disabled="isAdd" color="#62C4E3">
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </td>
                <td class="text-center">{{ index + 1 }}</td>

                <td class="text-center">
                  {{
                    item.cost_per_truck_created_at
                      ? item.cost_per_truck_created_at
                      : "--"
                  }}
                </td>
                <td class="text-center">
                  {{ item.unload_load_date1 ? item.unload_load_date1 : "--" }}
                </td>
                <td class="text-center">
                  {{ item.wh_factory_1_name ? item.wh_factory_1_name : "--" }}
                </td>
                <td class="text-center">
                  {{ item.qty ? item.qty : "--" }}
                </td>
                <td class="text-center">
                  {{ item.driver_name ? item.driver_name : "--" }}
                </td>
                <td class="text-center">
                  {{ item.truck_license_name ? item.truck_license_name : "--" }}
                </td>
                <td class="text-center">
                  {{
                    item.trailer_license_name ? item.trailer_license_name : "--"
                  }}
                </td>
                <td class="text-center">
                  <div class="d-flex align-center justify-center">
                    {{ item.job_status_name ? item.job_status_name : "--" }}
                    <v-btn
                      v-if="item.job_status_name == 'Draft'"
                      dark
                      max-height="25"
                      color="#338A68"
                      @click="onHandleToPending(item)"
                      class="ml-2 text-capitalize px-1 font-weight-bold"
                    >
                      To pending
                    </v-btn>
                  </div>
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
              </tr>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    />
  </div>
</template>

<script>
import AutocompleteInput from "../select/AutocompleteInput.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextInput from "../input/TextInput.vue";
export default {
  components: { AutocompleteInput, Loading, TextInput },
  props: {
    headers: Array,
    headersChild: Array,
    driverList: Array,
    truckLicenseList: Array,
    trailerLicenseList: Array,
    items: Array,
    nameTable: String,
    addItem: Function,
    deleteItem: Function,
    saveItem: Function,
    sendMail: Function,
    editItem: Function,
    onCancel: Function,
    onHandleToPending: Function,
    indexMain: {
      default: "",
    },
    indexChild: Number,
    isClearSelected: Boolean,
    isClearSubSelected: Boolean, // clear select ที่อยู่ใน sub tatle
    clearSelected: Function,
    acceptTenderItem: Function,
    tenderItem: Function,
    selectOrder: Function,
    isChangePaginateAndClearForm: Boolean,
  },
  data() {
    return {
      valMain: [],
      valSub: [],
      isEdit: false,
      mainIndex: null,
      mainItem: null,
      isCancel: false,
      isLoading: false,
      fullPage: true,
      isAdd: false,
      statusAdd: false,
      expanded: [],
      singleExpand: false,
      jobStatusList: [
        { job_status_id: 1, job_status_name: "Pending" },
        { job_status_id: 2, job_status_name: "Complete" },
      ],
      containerSizeList: [
        { container_size_id: "1", size: "20'" },
        { container_size_id: "2", size: "20'OT" },
        { container_size_id: "3", size: "20'FR" },
        { container_size_id: "4", size: "20'RF" },
        { container_size_id: "5", size: "20'ISO TANK" },
        { container_size_id: "6", size: "40'" },
        { container_size_id: "7", size: "40'HC" },
        { container_size_id: "8", size: "40'OT" },
        { container_size_id: "9", size: "40'FR" },
        { container_size_id: "10", size: "40'RF" },
        { container_size_id: "11", size: "45'" },
      ],
      renderComponent: true,

      cyPlaceList: [{ id: 1, name: "MPT" }],
      driverListDefault: [],
      truckLicenseListDefault: [],
      trailerLicenseListDefault: [],
    };
  },
  created() {
    this.driverListDefault = this.driverList;
  },
  watch: {
    //! clear form เวลากด add/edit tender แล้วไม่ได้ save
    isChangePaginateAndClearForm: {
      handler: function(newValue) {
        this.isAdd = false;
        this.isCancel = false;
        this.isEdit = false;
      },
    },

    //! clear selected หลังจากที่ทำ action ต่างๆเช่น เปลี่ยน status, create cost/truck
    isClearSelected: function(newVal) {
      if (!newVal) {
        this.valMain = [];
        this.valSub = [];
      }
    },

    //! clear Sub table selected หลังจากที่ทำ action ต่างๆเช่น เปลี่ยน status, create cost/truck
    isClearSubSelected: function(newVal) {
      if (!newVal) {
        this.valMain = [];
        this.valSub = [];
      }
    },

    //! set data Lv2 ที่ select กลับไปหน้า AcceptTender
    valSub: function(newVal) {
      if (newVal.length > 0) {
        this.clearSelected(true);
        this.acceptTenderItem(newVal);
      } else {
        this.clearSelected(false);
        this.acceptTenderItem([]);
      }
    },

    //! set data Lv1 ที่ select กลับไปหน้า AcceptTender
    valMain: function(newVal) {
      if (newVal.length > 0) {
        this.clearSelected(true);
        this.tenderItem(newVal);
      } else {
        this.clearSelected(false);
        this.tenderItem([]);
      }
    },
  },

  methods: {
    updateDataChild(text, item, value) {
      item[text] = value;
    },
    updateDataChildSelect(item, value, text) {
      if (text === "driverList") {
        item.driver_id = value.driver_id;
        item.driver_name = value.driver_name;
        //! auto field truck and driver
        item.truck_license_id = value.truck_plate_no;
        item.truck_license_name = value.truck_plate_no;
        item.truck_supply_id = value.truck_supply_id;
        item.trailer_license_id = value.tail_plate_no;
        item.trailer_license_name = value.tail_plate_no;
      } else if (text === "truckLicenseList") {
        item.truck_license_id = value.truck_plate_no;
        item.truck_license_name = value.truck_plate_no;
        item.truck_supply_id = value.truck_supply_id;
      } else {
        item.trailer_license_id = value.tail_plate_no;
        item.trailer_license_name = value.tail_plate_no;
      }
    },
    async onHandleAddItem(item) {
      const ft = await this.filterList(item.carrier_id);
      if (ft) {
        this.items.forEach((data) => {
          if (data.tender_id == item.tender_id) {
            if (data.status === null || data.status === "cancel") {
              this.$swal.fire({
                title: "Something went wrong!",
                text:
                  "Can't Create Cost/Truck because status tender is not approve",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
            } else {
              //! check จำนวน qty
              let total = 0;
              item.accept_tender.forEach((data) => {
                total += parseInt(data.qty);
              });
              if (total == item.qty) {
                this.$swal.fire({
                  title: "Something went wrong!",
                  text: "รายการเต็มแล้ว เพิ่มไม่ได้",
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });
              } else {
                this.isAdd = true;
                this.isEdit = true;
                this.isCancel = true;
                this.mainItem = item;
                this.addItem(item);
              }
            }
          }
        });
      }
    },

    onHandleSaveChild(item, typeSave) {
      if (
        item.qty <= 0 ||
        !item.driver_id ||
        !item.truck_license_id ||
        !item.trailer_license_id ||
        !item.job_status_id
      ) {
        this.$swal.fire({
          icon: "warning",
          title: "Please, Select!",
          text: "กรุณากรอกข้อมูลให้ครบ",
        });
      } else {
        let total = 0;
        this.items.forEach((data) => {
          if (data.tender_id == item.tender_id) {
            // หา row item ที่ตรงกับ data ที่อยู่ใน items
            data.accept_tender.forEach((at) => {
              // loop เพื่อหาจำนวน qty ที่อยู่ใน row accept_tender นั้นๆ
              total += parseInt(at.qty); //บวก qty
            });
            // check จำนวน qty ทั้งหมดที่กรอกไป กับ accept_tender ว่าเกินที่ tender กำหนดมาไหม
            if (parseInt(data.qty) < total) {
              this.$swal.fire({
                icon: "warning",
                title: "warning QTY!",
                text: "จำนวน Qty ไม่ถูกต้อง",
              });
            } else {
              if (typeSave === "save") {
                this.isAdd = false;
                this.isEdit = false;
                this.isCancel = false;
              } else {
                this.isEdit = true;
              }

              this.saveItem(item, typeSave);
            }
          }
        });
      }
    },

    filterList(carrier_id) {
      this.driverListDefault = [];
      this.truckLicenseListDefault = [];
      this.trailerLicenseListDefault = [];

      // หา driver จาก carrier
      this.driverList.forEach((data) => {
        if (data.carrier_id == carrier_id) {
          this.driverListDefault.push(data);
        }
      });

      // หา truck จาก carrier
      this.truckLicenseList.forEach((data) => {
        if (data.carrier_id == carrier_id) {
          this.truckLicenseListDefault.push(data);
        }
      });

      // หา trailer จาก carrier
      this.trailerLicenseList.forEach((data) => {
        if (data.carrier_id == carrier_id) {
          this.trailerLicenseListDefault.push(data);
        }
      });

      return true;
    },

    onHandleCancel(item, i) {
      this.isAdd = false;
      this.isCancel = false;
      if (this.isEdit) {
        this.items.find((element) => {
          if (element.tender_id === item.tender_id) {
            element.accept_tender = element.accept_tender.filter(function(
              val,
              key
            ) {
              return key !== i;
            });
          }
        });
        this.isEdit = false;
      }
    },
    onHandleEditChild(item, index) {
      this.isAdd = true;
      this.isEdit = false;
      this.isCancel = true;
      this.filterList(item.carrier_id);
      this.editItem(item, index);
    },
    onHandleDeleteChild(item, index) {
      this.isAdd = false;
      if (this.isEdit === this.isCancel) {
        this.deleteItem(item, index);
      } else {
        this.onCancel();
      }
      this.isCancel = false;
    },
    checkQty(item) {
      var total = 0;
      if (item.accept_tender && item.accept_tender.length == 0) {
        return total;
      } else {
        item.accept_tender.forEach((item) => {
          total += parseInt(item.qty);
        });
        return total;
      }
    },
  },
};
</script>

<style scope>
/* .test thead.v-data-table-header {
  background-color: #4b5768;
} */
.table-color {
  background-color: #99ca545e;
}
</style>
