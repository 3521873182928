<template>
  <div style="background-color: #F1F1F1;" class="fill-height">
    <!-- <img
      style="position: absolute;top: 1rem;right: 1rem"
      width="150"
      src="../../../assets/images/images.jpeg"
    /> -->
    <v-layout justify-center align-center class="fill-height">
      <v-flex xs6>
        <div class="d-flex justify-center mb-6">
          <img
            width="150"
            style="border-radius: 100%;"
            src="../../../assets/images/images-2.jpg"
          />
        </div>
        <v-card elevation="4" color="#fff">
          <v-card-title class="justify-center">
            <h3 class="headline">
              WP Logistics
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-12">
            <p>Sign in with your email and password:</p>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                outline
                :rules="[(v) => !!v || 'field is required']"
                label="Email"
                type="text"
                required
                v-model="form.email"
                @keyup.enter="login"
              ></v-text-field>
              <v-text-field
                :rules="[(v) => !!v || 'field is required']"
                outline
                required
                label="Password"
                type="password"
                v-model="form.password"
                @keyup.enter="login"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="px-12 pt-6">
            <v-btn
              :disabled="!valid"
              :dark="valid"
              block
              depressed
              color="#262F6F"
              @click="login"
            >
              <strong style="color: #fff;">Login</strong>
            </v-btn>
          </v-card-actions>

          <v-card-actions class="justify-center pb-4">
            <div
              class="link"
              style="color: #1F8FCC; cursor: pointer;"
              @click="$router.push('/forgot-password')"
            >
              Forgot password?
            </div>
          </v-card-actions>
          <Loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="fullPage"
          />
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      valid: true,
      form: {
        password: null,
        email: null,
      },
    };
  },
  components: {
    Loading,
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.$store
          .dispatch("auth/login", this.form)
          .then((res) => {
            this.isLoading = false;
            if (!res.success) {
              this.$swal.fire({
                title: "Something went wrong!",
                text: "Email ro Password went wrong!",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
            } else {
              // this.$swal.fire({
              //   position: "top-end",
              //   icon: "success",
              //   title: "Login successfully!",
              //   showConfirmButton: false,
              //   timer: 1500,
              // });
              this.$router.push("/");
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status == 404) {
              this.$swal.fire({
                icon: "error",
                title: error.response.statusText,
                text: "Email ro Password went wrong!",
              });
            }
          });
      }
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        // console.log("logout success !");
      });
    },
  },
};
</script>

<style>
.v-btn,
.v-card {
  border-radius: 4px;
}

.v-card__title {
  text-transform: uppercase;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
