<template>
  <div id="app">
    <v-app>
      <Sidebar v-if="$route.name !== 'Login'" />

      <v-app-bar app v-if="$route.name !== 'Login'" color="#fff">
        <v-layout pl-6 py-1 class="justify-center align-center">
          <v-flex shrink mr-2>
            <img width="40px" src="./assets/images/images-2.jpg" alt="" />
          </v-flex>
          <v-flex>
            <span class="mr-3 font-weight-bold">WP</span>
            <span>LOGISTICS</span>
          </v-flex>

          <v-spacer></v-spacer>
        </v-layout>
      </v-app-bar>

      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>

    <!-- <div class="version-control">
      <div class=" font-weight-bold">Version:</div>
      0.9.1
      <div class=" font-weight-bold">Release date:</div>
      16/08/2023
    </div> -->
  </div>
</template>
<script>
import Sidebar from "./views/layout/Sidebar.vue";
export default {
  data: () => ({ drawer: null }),
  components: { Sidebar },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;300&display=swap");

#app {
  font-family: "Prompt", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.version-control {
  font-size: 12px;
  position: fixed;
  bottom: 10px;
  /* right: 0; */
  padding: 5px 20px;
  /* background: rgb(160, 160, 160); */
}
.content {
  max-width: calc(100% - 40px);
}
</style>
