<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
import Sidebar from "./Sidebar";
export default {
  components: {
    // Sidebar,
    // Navbar,
  },
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: null,
    links: [
      ["mdi-inbox-arrow-down", "Inbox"],
      ["mdi-send", "Send"],
      ["mdi-delete", "Trash"],
      ["mdi-alert-octagon", "Spam"],
    ],
  }),
  // get vuex menu state
  computed: {
    activeMenu() {
      return this.$store.state.menu.activeMenu;
    },
  },
};
</script>

<style lang="css">
.content {
  max-width: calc(100% - 40px);
}
</style>
